<template>
  <span class="whitespace-nowrap">
    <dropdown width="180" v-if="resource.isEvent && resource.clients.length > 1">
      <template v-slot:reference>
        <span style="cursor: pointer; display: inline; z-index: 10">
          <div :class="`rounded-full opacity-50 table-cell w-[8px] h-[8px] ${rsvpColor}`"></div>
        </span>
      </template>
      <dropdown-section style="max-height: 250px">
        <dropdown-item
          v-if="rsvp != true"
          @click="updateClientRsvp(true)"
          key="positive"
          title="positive"
          style="padding: 0 10px"
          class="flex justify-between"
        >
          <div class="flex items-center">
            <div class="rounded-full opacity-50 w-[8px] h-[8px] mr-[10px] bg-green-600"></div>
            {{ $t("inbox.messageParticipant.attendance.true") }}
          </div>
        </dropdown-item>
        <dropdown-item
          v-if="rsvp != false"
          @click="updateClientRsvp(false)"
          key="negative"
          title="negative"
          style="padding: 0 10px"
          class="flex justify-between"
        >
          <div class="flex items-center">
            <div class="rounded-full opacity-50 w-[8px] h-[8px] mr-[10px] bg-red-600"></div>
            {{ $t("inbox.messageParticipant.attendance.false") }}
          </div>
        </dropdown-item>
        <dropdown-item
          v-if="rsvp != null"
          @click="updateClientRsvp(null)"
          key="null"
          title="null"
          style="padding: 0 10px"
          class="flex justify-between"
        >
          <div class="flex items-center">
            <div class="rounded-full opacity-50 w-[8px] h-[8px] mr-[10px] bg-gray-600"></div>
            {{ $t("inbox.messageParticipant.attendance.null") }}
          </div>
        </dropdown-item>
      </dropdown-section>
    </dropdown>
    <nice-popover trigger="hover" width="275px" transition="none" :show-arrow="false" :offset="0">
      <template #reference>
        <router-link
          v-if="participant.client && participant.client.path"
          :to="participant.client.path"
          class="msg-participant"
        >
          {{ name }}
        </router-link>
        <span v-else class="msg-participant">
          {{ name }}
          <span v-if="name != participant.email && participant.sender" class="text-gray-600 text-sm font-normal">
            &lt;{{ participant.email }}&gt;
          </span>
        </span>
      </template>
      <template #default>
        <section class="p-3 text-center" ref="sectionRef">
          <p>
            <strong :title="participant.name">{{ name }}</strong>
          </p>
          <p v-redacted class="mt-1">
            <a @click.prevent="quickviewMailForClient(participant)">
              {{ participant.email }}
            </a>
            <copy-to-clipboard :appendTo="$refs.sectionRef" :text="participant.email" class="copy-button" />
            <nice-tooltip
              v-if="(!participant.client && participant.email) || editable"
              :content="$t('inbox.messageParticipant.assignClient')"
              :append-to="$refs.sectionRef"
            >
              <a href="#" @click.prevent="openAssignClientDialog" class="ml-1 fs-12">
                <fa-icon name="plus" />
              </a>
            </nice-tooltip>
          </p>
          <p v-redacted v-if="participant.client && participant.client.phone" class="mt-1">
            <a
              :href="$customFilters.phonify(participant.client.phone)"
              class="link"
              :data-client-id="participant.client.id"
            >
              {{ participant.client.phone }}
            </a>
          </p>
          <whatsapp-button v-if="participant.client?.phone" :phone="participant.client?.intlPhone" leftMargin />
          <div class="mt-2">
            <a href="#" v-if="!participant.client || !participant.client.path" @click.prevent="createClient">
              <fa-icon name="plus" class="mr-1" />
              {{ $t("inbox.messageParticipant.createClient") }}
            </a>
            <router-link :to="participant.client.path" v-else>
              {{ $t("inbox.messageParticipant.link") }}
              <fa-icon name="arrow-right" />
            </router-link>
          </div>
        </section>
        <form-dialog
          :title="$t('inbox.messageParticipant.assigning')"
          v-model:visible="assignDialogVisible"
          :saving="assigning"
          @submit="assignClient"
          :submitButtonTitle="$t('inbox.messageParticipant.assigning')"
          append-to-body
        >
          <form-section>
            <form-row :title="$t('inbox.messageParticipant.linkedClient')">
              <client-select ref="clientSelect" v-model="clientId" include-children />
            </form-row>
          </form-section>
        </form-dialog>
      </template>
    </nice-popover>
    <nice-tooltip
      v-if="participant.client && participant.client.warningNotice"
      :content="participant.client.warningNotice"
    >
      <fa-icon name="exclamation-triangle" class="text-red-700" />
    </nice-tooltip>
  </span>
</template>

<script>
import eventBus from "@/config/event-bus"

export default {
  emits: ["update"],
  props: {
    participant: {
      type: Object,
    },
    taskId: {
      type: Number,
      default: undefined,
    },
    editable: {
      type: Boolean,
      default: false,
    },
    resource: {
      type: Object,
    },
  },
  data() {
    return {
      assignDialogVisible: false,
      assigning: false,
      clientId: null,
    }
  },
  methods: {
    updateClientRsvp(value) {
      const payload = {
        taskId: this.resource.id,
        clientId: this.participant.client.id,
        attending: value,
      }
      this.$api
        .mutation("updateTaskClientRsvp", payload, "clientId attending")
        .then(response => {
          this.$emit("update")
        })
        .catch(error => {
          App.alert(this.$t("inbox.messageParticipant.attendance.error"))
        })
    },
    createClient() {
      this.$axios.get(`/services/contacts/new?${$.param(this.newContactPayload)}`).then(({ data }) => {
        this.$emit("open-client-form", data)
      })
    },
    openAssignClientDialog() {
      this.assignDialogVisible = true
      this.$nextTick(() => {
        this.$refs.clientSelect.focus()
      })
    },
    assignClient() {
      this.assigning = true
      if (this.$parent.messageId) {
        this.$axios
          .post(`/services/messages/${this.$parent.messageId}/assign_client_with_email`, {
            client_id: this.clientId,
            email: this.participant.email,
          })
          .then(_ => {
            App.flashy(this.$t("inbox.messageParticipant.success"))
            this.assignDialogVisible = false
            this.$parent.clientCreated()
          })
          .finally(_ => {
            this.assigning = false
          })
      } else if (this.resource) {
        this.$api
          .mutation("assignClient", {
            clientId: this.clientId,
            resourceId: this.resource.id,
            resourceType: this.resource.type,
          })
          .then(_ => {
            App.flashy(this.$t("inbox.messageParticipant.success"))
            this.assignDialogVisible = false
            this.$parent.clientCreated()
          })
          .finally(_ => {
            this.assigning = false
          })
      }
    },
    quickviewMailForClient(client) {
      eventBus.$emit("quick-view", {
        key: "client",
        type: "mail",
        mode: "edit",
        params: {
          source: {
            taskId: this.taskId,
            clientIds: [client.client ? client.client.id : client.id],
            email: client.client ? client.client.email : client.email,
          },
        },
      })
    },
  },
  computed: {
    name() {
      if (this.participant.client) return this.participant.client.name
      return this.participant.name || this.participant.email || this.$t("inbox.messageParticipant.unknown")
    },
    newContactPayload() {
      return {
        message_id: this.$parent.messageId,
        email: this.participant.email,
        first_name: this.participant.name ? this.participant.name.split(" ")[0] : "",
        last_name: this.participant.name ? this.participant.name.split(" ")[1] : "",
        phone: this.participant.phone,
      }
    },
    rsvp() {
      return this.resource.clientsRsvp?.find(rsvp => rsvp.clientId == this.participant.id)?.attending
    },
    rsvpColor() {
      return this.rsvp == null ? "bg-gray-600" : this.rsvp ? "bg-green-600" : "bg-red-600"
    },
  },
}
</script>

<style scoped>
.msg-participant {
  display: inline-block;
  padding: 2px 6px;
  border-radius: 3px;
  margin-right: 0.2rem;
}
a.msg-participant {
  color: #0c40b0;
}
i.copy-button {
  color: #0c40b0;
}
.msg-participant:hover {
  background: #f1f1f1;
}

.msg-sender {
  font-weight: 500;
}
</style>
